import React, { useState, useEffect } from "react"

import Layout from "../components/layout/layout"
import Lottie from "react-lottie"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const [matchesOldUrl, setMatch] = useState(false)

  useEffect(() => {
    const url = window.location.href
    for (let i = 0; i < urls.length; i++) {
      const potentialMatchedUrl = new RegExp(urls[i], "g")
      if (url.match(potentialMatchedUrl)) {
        setMatch(true)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" />
      <section
        className="uk-section uk-section-primary"
        data-uk-scrollspy="target: h1, h2, .height-xsmall, .phone-wrapper, .uk-card; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        <div className="uk-container">
          <div className="uk-position-relative uk-flex uk-flex-middle uk-flex-center">
            <div className="hero-outer-circle uk-visible@s"></div>
            <div className="hero-inner-circle uk-visible@s"></div>
          </div>

          {matchesOldUrl ? (
            <>
              <h1>Under construction</h1>
              <h2 style={{ textAlign: "center" }}>Please check back later.</h2>
            </>
          ) : (
            <>
              <h1>404</h1>
              <h2 style={{ textAlign: "center" }}>
                We couldn't find that page.
              </h2>
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

const urls = [
  "/blog",
  "/app-request",
  "/amtrak-guest-rewards-program-overview",
  "/allegiant-air-myallegiant-rewards-program-overview",
  "/norwegian-rewards-program-overview",
  "/bass-pro-outdoor-rewards-program-overview",
  "/plenti-rewards-program-overview",
  "/mycruise-rewards-program-overview",
  "/american-express-blue-sky-rewards-program-overview",
  "/british-airways-avios-reward-program-overview",
  "/asiana-airlines-rewards-program-overview",
  "/citi-thankyou-rewards-program-overview",
  "/alaska-airlines-rewards-program-overview",
  "/disney-rewards-program-overview",
  "/expedia-rewards-program-overview",
  "/hilton-hhonors-rewards-program-overview",
  "/hyatt-gold-passport-rewards-program-overview",
  "/ihg-rewards-program-overview",
  "/mercedes-benz-rewards-program-overview",
  "/free-spirit-rewards-program-overview",
  "/virgin-america-elevate-rewards-program-overview",
  "/southwest-rapid-rewards-program-overview",
  "/ritz-carlton-rewards-program-overview",
  "/united-mileageplus-rewards-program-overview",
  "/marriott-rewards-program-overview",
  "/discover-rewards-program-overview",
  "/delta-skymiles-rewards-program-overview",
  "/american-airlines-aadvantage-rewards-program-overview",
  "/capital-one-rewards-program-overview",
  "/american-express-membership-rewards-program-overview",
  "/chase-ultimate-rewards-program-overview",
  "/bank-of-america-rewards-program-overview",
  "/starwood-preferred-guest-spg-rewards-program-overview",
  "/how-credit-card-companies-make-money-and-how-you-can-make-money-from-them",
  "/how-opening-and-closing-credit-cards-impact-your-credit-score",
  "/what-is-a-credit-score-and-how-is-it-calculated",
  "/credit-card/",
]

export default NotFoundPage
